/*Parallax Init*/
var scene = document.getElementById('scene');
var parallax = new Parallax(scene);	

var device = $("#device");
var current_device = '';
var current_device_target = '';
var cursor_y = 300;
var cursor_x = 300;

var view_offset_x = 300;
var entered = false;

/* Vivus SVG animation init */
var auto_svg = new Vivus('auto_svg', {type: 'sync', duration: 60}, function(){});		
var education_svg = new Vivus('education_svg', {type: 'sync', duration: 60}, function(){});	
var retail_svg = new Vivus('retail_svg', {type: 'sync', duration: 60}, function(){});	
var realty_svg = new Vivus('realty_svg', {type: 'sync', duration: 60}, function(){});	
var gaming_svg = new Vivus('gaming_svg', {type: 'sync', duration: 60}, function(){});	
var marketing_svg = new Vivus('marketing_svg', {type: 'sync', duration: 60}, function(){});	

var active_icon = false;
var can_device = false;
var on_device_menu = false;
var transitioning = false;
var mousemove = false;
var $tracker = false;
var stop_animation = true;
var stop_animation = false;

var force_hide = 0;

var audio_timeout = 0;
var transition_timeout = 0;
var clone_timeout = 0;

var is_mobile = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));

if(is_mobile){
	$('body').css('background-image', 'url(images/BG/main.jpg)');
	$('.tracker').css({width: '150px', height: '150px'});

	function preventBehavior(e) {
	    e.preventDefault(); 
	};

	document.addEventListener("touchmove", preventBehavior, false);
}

if(mousemove){	
	$(document).mousemove(function(e){
		if(current_device && can_device && !on_device_menu){
		    device.css({left:e.pageX-cursor_x, top:e.pageY-cursor_y});                     

		    // trackers follow icon movement
			$('#trackers .tracker').each(function(index, el) {    
				left_offset = 50;
				top_offset = 0;

				if(is_mobile){
					left_offset = 280;
					top_offset = 60;
				}

			  	offset = $($(el).attr('data-track') + ' .layer-wrap').offset();
				$(el).css({left:offset.left-left_offset, top:offset.top-top_offset});      
		    });
		}	
	});
}else{
	var show_overlay_timeout = 0;
	
	$('#crosshair').draggable({
		scroll: false,
		start: function() {
			// console.log('start');
		},
		drag: function() {	
			onDrag();				
		},
		stop: function() {
			if(!active_icon){
				$('#crosshair').show();
			}
		}
	});
	$('#device').draggable({
		// containment: (is_mobile?false:"body"),
		// containment: "window",
		scroll: false,
		start: function() {
			// console.log('start');
		},
		drag: function() {	
			onDrag();				
		},
		stop: function() {
			if(!active_icon){
				$('#crosshair').show();
			}else{				
				$('#crosshair').hide();
			}
		}
	});
}

function onDrag(){
	clearTimeout(show_overlay_timeout);
	$('#crosshair').show();

	$('.vr #crosshair').removeClass('info');
		
	// trackers follow icon movement
	var $tracker_container = $('#trackers');
	var $tracker_idx = $('.tracker', $tracker_container).length;

	for(var i = 0; i < $tracker_idx; i++) {
		var tracker = $('.tracker', $tracker_container)[i];
		offset = $($(tracker).attr('data-track') + ' .layer-wrap').offset();
	  	left_offset = 0;
		top_offset = 0;
		if(is_mobile){

			left_offset = -100;
			top_offset = -60;
		}
		$(tracker).css({'transform' : 'translate3d('+(offset.left-left_offset)+'px ,'+(offset.top-top_offset)+'px, 0px)','transform-style' : 'preserve-3d'}); 
	}


	if(collision($('#tracker1'), $('#crosshair'))){						
		var tracker = $('#tracker1');
		var icon = tracker.attr('data-track');

		if(!is_mobile && !active_icon){
			show_overlay_timeout = setTimeout(function(){
				showOverlay(icon, tracker);
			},250);

		}else{							
			showOverlay(icon, tracker, true);									
		}				
	}else if(collision($('#tracker2'), $('#crosshair'))){					
		var tracker = $('#tracker2');
		var icon = tracker.attr('data-track');

		if(!is_mobile && !active_icon){
			show_overlay_timeout = setTimeout(function(){
				showOverlay(icon, tracker);
			},250);
		}else{							
			showOverlay(icon, tracker, true);									
		}				
	}else if(collision($('#tracker3'), $('#crosshair'))){					
		var tracker = $('#tracker3');
		var icon = tracker.attr('data-track');

		if(!is_mobile && !active_icon){
			show_overlay_timeout = setTimeout(function(){
				showOverlay(icon, tracker);
			},250);
		}else{							
			showOverlay(icon, tracker, true);									
		}

	}else if(collision($('#tracker4'), $('#crosshair'))){	
		var tracker = $('#tracker4');
		var icon = tracker.attr('data-track');

		if(!is_mobile && !active_icon){
			show_overlay_timeout = setTimeout(function(){
				showOverlay(icon, tracker);
			},250);
		}else{							
			showOverlay(icon, tracker, true);									
		}
	}else if(collision($('#tracker5'), $('#crosshair'))){					
		var tracker = $('#tracker5');
		var icon = tracker.attr('data-track');

		if(!is_mobile && !active_icon){
			show_overlay_timeout = setTimeout(function(){
				showOverlay(icon, tracker);
			},250);
		}else{							
			showOverlay(icon, tracker, true);									
		}
	}else if(collision($('#tracker6'), $('#crosshair'))){				
		var tracker = $('#tracker6');
		var icon = tracker.attr('data-track');

		if(!is_mobile && !active_icon){
			show_overlay_timeout = setTimeout(function(){
				showOverlay(icon, tracker);
			},250);
		}else{							
			showOverlay(icon, tracker, true);									
		}
	}else{	
		if(!is_mobile){
			hideOverlay();
		}else{
			$('#press').fadeOut('500', function() {});
		}			
	}
}

/* Animate Icons */
$('.icon').each(function(index, el) {
	$(el).sprite({fps: 12, no_of_frames: 65});	
});

if(!stop_animation){
	/* Track overlay(ar) to icon */	
	setInterval(function(){	
		if(current_device && active_icon){	
			left_offset = 200;
			top_offset = 100;		

			offset = $(active_icon).find('.layer-wrap').offset();						
			overlay = $(active_icon).find('.layer-wrap').attr('data-overlay');						
			$(overlay).css({left:offset.left-left_offset, top:offset.top-top_offset, position:'fixed'});			
		}
	}, 50);
}

var $icon = null;
var $tracker = null;

function showOverlay(icon, tracker, mobile){	
	if(mobile){		
		if(!active_icon){
			$('#press').fadeIn('500', function() {});
		}
		$icon = icon;
		$tracker = tracker;
		return false;
	}

	if(current_device && !active_icon){
		$icon = $(icon);
		active_icon = $icon;	
		$tracker = tracker;
		if($('#ar_back').is(':visible')) {
			$('#ar_back').fadeOut('fast');
			$('#ar_back').removeAttr('style');
		}
		clearTimeout(clone_timeout);
		clone_timeout = setTimeout(function(){
			// double check active icon - to avoid displaying of background when no icon is selected
			if(active_icon) {
				$('#ar_back').css('background', 'url('+tracker.attr('data-bg')+')');		
				$('#ar_back').fadeIn('fast');
			}

			/*Add Cloned icon to overlay*/
			if(typeof $icon.clone === 'function') {
				cloned = $icon.clone();				
				appendHere = $icon.find('.layer-wrap').attr('data-overlay');				
				if($(appendHere).find('.layer').length){				
					$(appendHere).find('.layer').remove();	
				}
				
				$(cloned).removeAttr('id style').addClass('active').appendTo(appendHere);				
				$(appendHere).find('.icon').sprite({fps: 12, no_of_frames: 65});
			}
		}, 1000)

		left_offset = 0;
		top_offset = 0;
		video_index = tracker.attr('data-video-index');
		track = tracker.attr('data-track');		
		overlay = $icon.find('.layer-wrap').attr('data-overlay');
		audio_overlay = $(tracker.attr('data-audio'));
		pause_all_audio();
		clearTimeout(audio_timeout);
		if(!isPlaying(audio_overlay.get(0))) {
			audio_timeout = setTimeout(function(){
				if(force_hide == 0) {
					audio_overlay.get(0).currentTime = 0;
					audio_overlay.get(0).volume = 1;
					audio_overlay.get(0).play();
				}
			}, 1200);
		}
		/* Hide all icons except active - USING OPACITY: 0 & POINTER-EVENTS: NONE */
		$('#scene > div.layer').removeClass('active').css('opacity','0').css('pointer-events','none') // hide all icons
		$('.tracker > div.tracker').css('opacity','0').css('pointer-events','none'); // hide all trackers
		$(track).css('opacity','1').css('pointer-events','visible'); // only show active icon
		$($tracker).css('opacity','1').css('pointer-events','visible'); // only show active tracker
		// $(track).addClass('active');

		if(current_device != 'vr'){
			$('#crosshair').fadeOut('fast', function() {});
		}else{
			// $('#container').css('z-index', 0);
		}

		$icon.find('.layer-wrap').removeClass('animate');
		// $icon.find('.layer-wrap').find('div').hide();		
		// $icon.find('.layer-wrap').find('div').show();


		// $('body').css('cursor', 'none'); // hide cursor					
		
		if(current_device == 'tablet'){
			switch(icon){
				case '#icon_education':
					// $icon.find('.icon').css({position:'relative', left: '-150px', top: '-100px'});
					$icon.find('.icon').css({position:'relative', transform: 'translate(-150px, -100px)'});
				break;
				case '#icon_retail':
					// $icon.find('.icon').css({position:'relative', left: '-150px', top: '100px'});
					$icon.find('.icon').css({position:'relative', transform: 'translate(-50px, 100px)'});
				break;
				case '#icon_gaming':
					// $icon.find('.icon').css({position:'relative', left: '-100px', top: '100px'});
					$icon.find('.icon').css({position:'relative', transform: 'translate(-100px, 100px)'});
				break;
				case '#icon_auto':
					// $icon.find('.icon').css({position:'relative', left: '20px', top: '-50px'});
					$icon.find('.icon').css({position:'relative', transform: 'translate(20px, -50px)'});
				break;
				case '#icon_marketing':
					// $icon.find('.icon').css({position:'relative', left: '-200px', top: '80px'});
					$icon.find('.icon').css({position:'relative', transform: 'translate(-200px, 80px)'});
				break;
				case '#icon_realty':
					// $icon.find('.icon').css({position:'relative', left: '-200px', top: '150px'});
					$icon.find('.icon').css({position:'relative', transform: 'translate(-200px, 150px)'});
				break;
			}
		}else{			
			$icon.find('.icon').css({position:'relative', transform: 'translate(0, 0)'});
		}

		if(is_mobile){
			$('#close').fadeIn('fast', function() {});
			$('#press').fadeOut('fast', function() {});

			bg = null;
			switch(icon){
				case '#icon_education':
					bg = 'education.jpg';
				break;
				case '#icon_retail':
					bg = 'retail.jpg';
				break;
				case '#icon_gaming':
					bg = 'gaming.jpg';
				break;
				case '#icon_auto':
					bg = 'auto.jpg';
				break;
				case '#icon_marketing':
					bg = 'marketing.jpg';
				break;
				case '#icon_realty':
					bg = 'realty.jpg';
				break;
			}


			afterTransition(0, overlay);
			$('body').css('background-image', 'url(images/BG/'+bg+')');
		}else{	
			video_slide = $($('#stage1').children('.slide')[0]);
			video_slide.show();
			video = video_slide.find('video');	
			try{
				switch(video_index) {
					case "1":
						video.get(0).currentTime = 30.7;
						startTime = 31.9;
						endTime = 45.1;							
					break;
					case "2" :
						video.get(0).currentTime = 90.7;
						startTime = 92.9;
						endTime = 105;							
					break;
					case "3" :
						video.get(0).currentTime = 45.8;
						startTime = 46.9;
						endTime = 60.1;							
					break;
					case "4" :
						video.get(0).currentTime = 15.8;
						startTime = 16.9;
						endTime = 30.1;							
					break;
					case "5" :
						video.get(0).currentTime = 60.8;
						startTime = 62.2;
						endTime = 75.1;							
					break;

					case "6" :
						video.get(0).currentTime = 75.8;
						startTime = 76.9;
						endTime = 90.1;						
					break;

				}
			}
			catch(err){
				// video.get(0).play();
			}			

				video.get(0).play();
			
			// $('#voice_over').attr('src', '');			
			// $('#voice_over').load();
			// $('#voice_over').get(0).play();

			clearTimeout(transition_timeout);
			transition_timeout = setTimeout(function(){
				if(force_hide == 0) {
					afterTransition(0, overlay);
				}
			},900);
		}
		
		playAudio('transition');			
	}else{
		// console.log('[tag]none');
	}
	return false;
}

function afterTransition(video_index, overlay){
	
	if(!active_icon){
		video_index = 0;
	}

	// $('#transitions').css('opacity','0').css('pointer-events','none');
	$('#trackers > div.tracker').css('opacity','1').css('pointer-events','visible');
	// $('#transitions').hide();
	// $('#trackers > div.tracker').show();

	// $('#stage1').children('.slide').css('opacity','0').css('pointer-events','none');

	$('.ar').css('opacity','1').css('pointer-events','visible');
	$('.ar-content').css('opacity','1').css('pointer-events','visible');


	education_svg.reset();
	retail_svg.reset();
	auto_svg.reset();
	gaming_svg.reset();
	marketing_svg.reset();
	realty_svg.reset();			

	// if(is_mobile){
		setTimeout(function(){
			overlayAnimation(overlay) 
		}, 500);
	// }else{		
	// 	overlayAnimation(overlay) 
	// }
}

function overlayAnimation(overlay){
	if(!active_icon) {
		$('.ar').fadeOut(10,showOverlayClosure);
		$('.ar-content').fadeOut(10);
	} else {
		showOverlayClosure();
	}

	function showOverlayClosure(){
		$(overlay).stop().fadeIn('fast', function() {
			if(active_icon){				
				/*Play animation on active*/
				switch(active_icon.attr('id')){
					case 'icon_education':
						education_svg.play(playAudio('load'));

					break;
					case 'icon_retail':						
						retail_svg.play(playAudio('load'));						
					break;
					case 'icon_auto':						
						auto_svg.play(playAudio('load'));						
					break;
					case 'icon_gaming':						
						gaming_svg.play(playAudio('load'));						
					break;
					case 'icon_marketing':						
						marketing_svg.play(playAudio('load'));						
					break;
					case 'icon_realty':						
						realty_svg.play(playAudio('load'));						
					break;
				}


				if(current_device && active_icon && current_device != 'phone'){		
					$(overlay).find('.ar-content').fadeIn('fast');
				}

				if(current_device != 'vr'){
					$('#crosshair').hide();
				}
			}

		});
	}
}

function hideOverlay(){

	if(current_device && active_icon){
		audio_overlay = $($tracker.attr('data-audio'));
		pause_all_audio();
		active_icon = false;

		// if(isPlaying(audio_overlay.get(0))) {
		audio_overlay.get(0).pause();

		$('#ar_back').hide();
		$('#ar_back').removeAttr('style');


		// }
		// video_index = $tracker.attr('data-video-index');
		if(!is_mobile){
			/* Pause initial video */
			video_slide = $($('#stage1').children('.slide')[0]);
			video = video_slide.find('video').get(0);						
			video.currentTime = 0;
			startTime = default_startTime;
			endTime = default_endTime;

			video.play();
			$('#transition_audio').get(0).volume = 0.15;
			$('#transition_audio').get(0).play();
		}else{
			$('#close').fadeOut('fast', function() {});
			$('body').css('background-image', 'url(images/BG/main.jpg)');
		}

				
		icon = $($tracker.attr('data-track'));
		// icon.find('.icon').destroy();
		icon.find('.icon').attr('style','');
		icon.find('.layer-wrap').addClass('animate');
		overlay = icon.find('.layer-wrap').attr('data-overlay');	

		$('.ar').css({'opacity':'0','pointer-events' : 'none'}).fadeOut('fast');
		$('.ar-content').css({'opacity':'0','pointer-events' : 'none'});

		$('#crosshair').stop().fadeIn('fast', function() {

		});	

		$('#scene .layer').removeClass('active').css({'opacity':'1','pointer-events' : 'visible'});	
		$('.tracker').css({'opacity':'1','pointer-events' : 'visible'});	

		$(overlay).stop().fadeOut('fast', function() {
			// $(overlay).css({'opacity':'0','pointer-events' : 'none'});
			// $('.ar').css({'opacity':'0','pointer-events' : 'none'});
			// $('.ar-content').css({'opacity':'0','pointer-events' : 'none'});

			force_hide = 1; 

		});
		setTimeout(function(){
			force_hide = 0;
		},1200);

		// audio_overlay.get(0).pause();

	} else {

	}
	return false;
}

var expanding = false;
var window_w = $( window ).width();
var window_w_adjusted = (window_w - (window_w / 2) + 300)

function bigHole(expand){	
	radius = 70;
	$('#vr_device').addClass('enlarge');	

	$('#vr_mask circle').animate(
		{r: window_w_adjusted},
		{
			duration: 1500,
			step: function(now){ 
				$(this).attr('r', now); 
				// $(this).attr('cx', $(this).attr('cx')-1); 
			},
			queue: false
		}
	);	

	$('#vr_mask #c1').animate(
		{cx: 0},
		{
			duration: 1500,
			step: function(now){ 
				// $(this).attr('r', now); 
				$(this).attr('cx', now); 
			},
			queue: false
		}
	);	

	$('#vr_mask #c2').animate(
		{cx: 1500},
		{
			duration: 1500,
			step: function(now){ 
				// $(this).attr('r', now); 
				$(this).attr('cx', now); 
			},
			queue: false
		}
	);
}

function smallHole(){	
	radius = window_w_adjusted;
	$('#vr_device').removeClass('enlarge');
	$('#vr_mask circle').animate(
		{r: 70},
		{
			duration: 1700,
			step: function(now){ 
				$(this).attr('r', now); 
			},
			complete: function(){
				$('#vr_device').fadeOut('fast');
				$('#hole').fadeOut('fast');
				$('#device_img').show();
			},
			queue: false
		}
	);	

	$('#vr_mask #c2').animate(
		{cx: (Math.max(0, ((width - 70) / 2) + $(window).scrollLeft()) + 145) },
		{
			duration: 1700,
			step: function(now){ 
				$(this).attr('cx', now); 
			},
			queue: false
		}
	);	

	$('#vr_mask #c1').animate(
		{cx: (Math.max(0, ((width - 70) / 2) + $(window).scrollLeft()) - 90) },
		{
			duration: 1700,
			step: function(now){ 
				$(this).attr('cx', now); 
			},
			queue: false
		}
	);		
}

function setDevice(device_class, url, x, y, offset_x, selected){
	var device_wrap = $('#device');	
	var active = 'active';

	/* Toggle No device selected */
	if(device_wrap.hasClass(device_class)){
		can_device = false;
		device_class = '';	
		url = '';		
		active = '';
		selected.removeClass('active');		
		$('body').css('cursor', 'default');		

		$('.ar').css('opacity','0').css('pointer-events','none');

		$('.ar-content').css('opacity','0').css('pointer-events','none');

		if(!is_mobile){
			video_slide = $($('#stage1').children('.slide')[0]);
			video_slide.addClass('on');						
			video = video_slide.find('video');								
			video.get(0).play();					

		}	

		smallHole();					
	}

	$('#hole').removeClass('animation-hole');
	hideOverlay();

		
	device_wrap.attr('class', '');
	device_wrap.addClass(device_class);
	current_device = device_class;

	switch(current_device){
		case 'phone':
			current_device_target = '.device-phone';			
			// $('.ar-content .content-wrapper').hide();
		break;
		case 'tablet':			
			current_device_target = '.device-tablet';
		break;
		case 'vr':
			current_device_target = '.device-phone';
		break;
	}	


	if(current_device != 'vr'){
		$('#device_img').attr('src', url);
		smallHole();		
	}else{		
		$('#device_img').attr('src', '');
		$('#device_img').hide();

	}

	cursor_x = x;
	cursor_y = y;
	view_offset_x = offset_x;

	$('#device_menu a').removeClass('active');
	selected.addClass(active);
	device.center();
		if(current_device == 'vr'){
			device.draggable('disable');
			
			width = $( window ).width();
			height = $( window ).height();
			$('#crosshair').css({
				'left': Math.max(0, ((width - $('#crosshair').outerWidth()) / 2) + $(window).scrollLeft()) + "px", 
				'top': Math.max(0, ((height - $('#crosshair').outerHeight()) / 2) + $(window).scrollTop()) + "px"
			}); // center crosshair
			
			$('#vr_device').css({
				'left': Math.max(0, ((width - $('#vr_device').outerWidth()) / 2) + $(window).scrollLeft()) + "px", 
				'top': Math.max(0, ((height - $('#vr_device').outerHeight()) / 2) + $(window).scrollTop()) + "px"
			});			

			$('#c1').attr('cx', Math.max(0, ((width - 70) / 2) + $(window).scrollLeft()) - 90);
			$('#c1').attr('cy', Math.max(0, ((height - 70) / 2) + $(window).scrollTop()) + 50);

			$('#c2').attr('cx', Math.max(0, ((width - 70) / 2) + $(window).scrollLeft()) + 145);
			$('#c2').attr('cy', Math.max(0, ((height - 70) / 2) + $(window).scrollTop()) + 50);

			$('#hole, #vr_device').fadeIn('400', function() {				
			});
			setTimeout(function(){
				bigHole(true);
			}, 400)

			setTimeout(function(){
				device.css({top:0, left:0});				
				device.addClass('transition');				
				device.find('#device_img').addClass('zoom');				
				
				// setTimeout(function(){
				// 	device.fadeOut('slow', function() {
				// 		// $('#device_img').attr('src', './images/cardboard-active.png');
				// 		device.fadeIn('fast', function() {

							can_device = true;					
				// 		});			
				// 	});				
				// },300);
			},300);
		}else{				
			$('#crosshair').removeAttr('style');
			$('.device-mask').removeAttr('style');
			device.draggable('enable');		
			device.removeClass('transition');				
			device.find('#device_img').removeClass('zoom');	
			can_device = true;
		}
	device.fadeIn('fast', function() {

	});

	return true;
}

/* Center an element */
jQuery.fn.center = function () {  
    this.css("top", Math.max(0, (($(window).height() - $(this).outerHeight()) / 2) + 
                                                $(window).scrollTop()) + "px");
    this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2) + 
                                                $(window).scrollLeft()) + "px");
    return this;
}

/* Device Menu Actions */
$('#device_phone').on('click', function(event) {
	event.preventDefault();	
	
	setDevice('phone', './images/phone.png', 300, 130, 300, $(this));			
});

$('#device_tablet').on('click', function(event) {	
	setDevice('tablet',  './images/tablet.png', 350, 230, 300, $(this));	
});

$('#device_vr').on('click', function(event) {
	event.preventDefault();	

	setDevice('vr',  './images/cardboard.png', 320, 180, 200, $(this));	
});

function collision($div1, $div2) {
	var x1 = $div1.offset().left;
	var y1 = $div1.offset().top;
	var h1 = $div1.outerHeight(true);
	var w1 = $div1.outerWidth(true);
	var b1 = y1 + h1;
	var r1 = x1 + w1;
	var x2 = $div2.offset().left;
	var y2 = $div2.offset().top
	var h2 = $div2.outerHeight(true);
	var w2 = $div2.outerWidth(true);
	var b2 = y2 + h2; 
	var r2 = x2 + w2;
	
	if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2){
		//top //bottom //left //right
		return false;
	}
	return true;
}

function playAudio(sound){
	player = $('#audio_player');
	switch(sound){
		case 'load':
			audio = 'audio/effects/load.mp3';
		break;
		case 'transition':
			audio = 'audio/effects/selection.mp3';
		break;
	}

	player.attr('src', audio);
	player.load();

	if(sound == 'load') {
		player.get(0).volume = 0.25;
	} else {
		player.get(0).volume = 0.15;
	}

	player.get(0).play();

	if(sound == 'load'){
		player.on('ended', function(){
		$('#background_audio').get(0).volume = 0.48;
			$('#background_audio').get(0).play();

		})
	}
}


$('#nav-icon').click(function(){
	$(this).toggleClass('open');
	if($(this).hasClass('open')){
		$('#menu').stop().fadeIn('500', function() {
			
		});
	}else{
		$('#menu').stop().fadeOut('500', function() {
			
		});
	}
});

$('#close').on('click', function(event) {
	event.preventDefault();
	hideOverlay();

});

$('#press').on('click', function(event) {
	event.preventDefault();	
	showOverlay($icon, $tracker);		
});


function isPlaying(audelem) { return !audelem.paused; }

function pause_all_audio() {
		all_audio = $('#voice_overs audio');
		var cnt = all_audio.length;
		for(var i = 0; i < cnt; i++) {
			var audio = all_audio[i];
			audio.pause();
		}

}